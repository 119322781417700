



































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { Reaction } from '@/admin/reactions';
import { useReactions } from '@/composition/reactions';
import { useNotification } from '@/composition/notification';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  reactionName: 'Reactions',
  components: {
    FcImage,
    FcStaticImage,
    FcRoleLoading,
  },
  props: {
    prevPage: {
      type: String,
      required: false,
    },
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('reactions'));

    const notification = useNotification();
    const reactions = useReactions();

    const headers = [
      { text: 'リアクション名', value: 'reactionName' },
      { text: 'アイコン', value: 'icon' },
    ];

    const reactionId = ref('');
    const reactionName = ref('');
    const icon = ref('');
    const isActive = ref(false);
    const isSaving = ref(false);

    const open = (item?: Reaction) => {
      if (item) {
        reactionId.value = item.reactionId;
        reactionName.value = item.reactionName;
        icon.value = item.icon;
      }
      isActive.value = true;
    };

    const close = () => {
      reactionId.value = '';
      reactionName.value = '';
      icon.value = '';
      isActive.value = false;
    };

    const save = async () => {
      if (!reactionName.value || !icon.value) {
        alert('未入力があります。');
        return;
      }

      const props = {
        reactionName: reactionName.value,
        icon: icon.value,
      };

      isSaving.value = true;
      try {
        if (reactionId.value) await reactions.editReaction(props, reactionId.value);
        else await reactions.createReaction(props);
        await reactions.getReactions();
        notification.notify(`${reactionId.value ? '更新しました。' : '作成しました。'}`);
      } catch (error) {
        notification.error(error);
      } finally {
        close();
        isSaving.value = false;
      }
    };

    return {
      pageTitle: 'リアクション管理',
      myRoleSettings,
      headers,
      reactions,
      reactionName,
      icon,
      isActive,
      isSaving,
      open,
      close,
      save,
    };
  },
});
